var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "schoolData",
          attrs: {
            "label-width": "150px",
            model: _vm.schoolData,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên trường", prop: "schoolName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập tên trường" },
                model: {
                  value: _vm.schoolData.schoolName,
                  callback: function ($$v) {
                    _vm.$set(_vm.schoolData, "schoolName", $$v)
                  },
                  expression: "schoolData.schoolName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "35px" },
              attrs: { label: "Mô tả", prop: "schoolDescription" },
            },
            [
              _c("el-input", {
                attrs: { rows: 7, type: "textarea", placeholder: "Nhập mô tả" },
                model: {
                  value: _vm.schoolData.schoolDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.schoolData, "schoolDescription", $$v)
                  },
                  expression: "schoolData.schoolDescription",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Địa chỉ", prop: "schoolAddress" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập địa chỉ" },
                model: {
                  value: _vm.schoolData.schoolAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.schoolData, "schoolAddress", $$v)
                  },
                  expression: "schoolData.schoolAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", width: "33.5%" },
              attrs: { label: "Mã xử lý" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.schoolData.verifyCode))])]
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", width: "33.5%" },
              attrs: { label: "SMS đã dùng", prop: "smsUsed" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.schoolData.smsUsed))])]
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", width: "33%" },
              attrs: { label: "SMS còn lại" },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.schoolData.smsTotal - _vm.schoolData.smsUsed)
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "content-2",
              staticStyle: { display: "flex", "flex-direction": "row" },
            },
            [
              _c(
                "div",
                { staticClass: "avt-info", staticStyle: { width: "80%" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Điện thoại", prop: "schoolPhone" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập số điện thoại" },
                        model: {
                          value: _vm.schoolData.schoolPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schoolData,
                              "schoolPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "schoolData.schoolPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ảnh bìa" } },
                    [
                      _vm.schoolData.schoolAvatar != null &&
                      _vm.showPicutreInitial
                        ? _c("el-image", {
                            staticStyle: { width: "150px", height: "150px" },
                            attrs: {
                              src: _vm.schoolData.schoolAvatar,
                              fit: "fill",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "avatar-uploader",
                          class: { hideUpload: !_vm.showUpload },
                          attrs: {
                            "show-file-list": true,
                            "list-type": "picture-card",
                            "on-change": _vm.handleChangeUpload,
                            "on-remove": _vm.handleRemove,
                            "auto-upload": false,
                            limit: "1",
                          },
                        },
                        [
                          _vm.schoolData.schoolAvatar != null
                            ? _c("span", [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                    staticStyle: { "font-size": "20px" },
                                  },
                                  [_vm._v("Cập nhật ảnh")]
                                ),
                              ])
                            : _c("span", [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                    staticStyle: { "font-size": "20px" },
                                  },
                                  [_vm._v("Thêm ảnh")]
                                ),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "concat-detail" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Email", prop: "schoolEmail" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập email" },
                        model: {
                          value: _vm.schoolData.schoolEmail,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schoolData,
                              "schoolEmail",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "schoolData.schoolEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Website", prop: "schoolWebsite" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập website" },
                        model: {
                          value: _vm.schoolData.schoolWebsite,
                          callback: function ($$v) {
                            _vm.$set(_vm.schoolData, "schoolWebsite", $$v)
                          },
                          expression: "schoolData.schoolWebsite",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Liên hệ 1", prop: "contactPhone1" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Số điện thoại 1" },
                        model: {
                          value: _vm.schoolData.contactPhone1,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schoolData,
                              "contactPhone1",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "schoolData.contactPhone1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Tên", prop: "contactName1" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập nội dung" },
                        model: {
                          value: _vm.schoolData.contactName1,
                          callback: function ($$v) {
                            _vm.$set(_vm.schoolData, "contactName1", $$v)
                          },
                          expression: "schoolData.contactName1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Liên hệ 2", prop: "contactPhone2" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Số điện thoại 2" },
                        model: {
                          value: _vm.schoolData.contactPhone2,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schoolData,
                              "contactPhone2",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "schoolData.contactPhone2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Tên", prop: "contactName2" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập nội dung" },
                        model: {
                          value: _vm.schoolData.contactName2,
                          callback: function ($$v) {
                            _vm.$set(_vm.schoolData, "contactName2", $$v)
                          },
                          expression: "schoolData.contactName2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Liên hệ 3", prop: "contactPhone3" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Số điện thoại 3" },
                        model: {
                          value: _vm.schoolData.contactPhone3,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.schoolData,
                              "contactPhone3",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "schoolData.contactPhone3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "input-below",
                      attrs: { label: "Tên", prop: "contactName3" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Nhập nội dung" },
                        model: {
                          value: _vm.schoolData.contactName3,
                          callback: function ($$v) {
                            _vm.$set(_vm.schoolData, "contactName3", $$v)
                          },
                          expression: "schoolData.contactName3",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.checkPermission(["schoolConfig_school_update"])
            ? _c(
                "el-button",
                {
                  staticStyle: { float: "right", "border-radius": "0" },
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("schoolData")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }